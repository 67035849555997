import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Form, Header, Icon, Label, Message, Popup, Statistic, Step } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { getAgencyAlertsFeed, getAgencyVehiclesFeed, getServicePageData } from '../../actions/servicePageActions';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { BaseServiceData, RtCoverage, ServiceStatusModel } from '../../types/servicePageTypes';
import Utils from '../../utilities/utils';
import { toReportRow, UnmatchedTripsReportDef, UnmatchedTripsReportHeader } from './csvReports';


interface Props {
    agency: AgencyStateType | undefined;
}

interface StatusFeedItemParameter {
    label: string;
    value: Nullable<number>;
    isValidFunc: (value: Nullable<number>) => boolean;
    formattedValue?: string;
    description?: string;
    fileName?: string;
    downloadFileFunc?: (agencyId: string) => Promise<Blob>;
    csvData?: object[];
    sectionName?: string
}

const styles = {
    calculatedValuesDescription: {
        fontSize: '12px',
        color: '#6D6D6D',
    } as React.CSSProperties,
    statusFeedItemLabel: {
        fontSize: '12px',
        fontWeight: 400,
        textTransform: 'none',
        textAlign: 'left',
    } as React.CSSProperties,
};

const ServiceStatusForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [dataState, setDataState] = useState<ServiceStatusModel | undefined>(undefined);

const latencyThreshold = config.StatusServiceSettings.SiriLatencyThreshold;
const coverageThreshold = config.StatusServiceSettings.SiriCoverageThreshold;

    useEffect(() => {
        (async () => await retrieveServiceData())();
    }, [agency?.id]);

    const retrieveServiceData = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const servicePageData = await getServicePageData(agency.id);
                setDataState(servicePageData);
            } catch {
                setDataState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setDataState(undefined);
        }
    };
    const downloadFeed = async (fileName: string, downloadFileFunc: (agencyId: string) => Promise<Blob>) => {
        if (agency && agency.id) {
            const fileResult = await downloadFileFunc(agency.id);
            fileName = `${fileName}_${moment(new Date()).format('YYYY_MM_DD_h_mm_A')}.json`;
            const url = window.URL.createObjectURL(fileResult);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }
    };
    const isStepCompleted = ({ vehiclesCoverage, tripsCoverage, vehiclesLatency, tripsLatency }: BaseServiceData) => {
        return Boolean(
            (vehiclesCoverage?.coverageValue === null || vehiclesCoverage?.coverageValue > coverageThreshold)
            && (tripsCoverage?.coverageValue === null || tripsCoverage?.coverageValue > coverageThreshold)
            && (vehiclesLatency === null || vehiclesLatency <= latencyThreshold)
            && (tripsLatency === null || tripsLatency <= latencyThreshold));
    };
    const isVehiclesStepCompleted = ({ vehiclesCoverage, vehiclesLatency }: BaseServiceData) => {
        return Boolean(
            (vehiclesCoverage?.coverageValue === null || vehiclesCoverage?.coverageValue > coverageThreshold)
            && (vehiclesLatency === null || vehiclesLatency <= latencyThreshold));
    };
    const isValidNumber = (value: Nullable<number>) => {
        return Boolean(value !== null);
    };
    const isValidCoverage = (coverage: Nullable<number>) => {
        return Boolean(coverage === null || coverage >= coverageThreshold);
    };
    const isValidLatency = (latency: Nullable<number>) => {
        return Boolean(latency === null || latency <= latencyThreshold);
    };
    const getStepNotificationIcon = () => {
        return <Icon name="exclamation triangle" color="red" />;
    };

    function getLatencyItem(latency: Nullable<number>, description?: string) {
        const formattedValue = latency !== null && latency >= 3600
            ? Utils.convertSecondsToHours({ seconds: latency })
            : latency !== null && latency > 0 ? Utils.convertSecondsToMinutes(latency) : '-';
        const label = latency !== null && latency >= 3600 ? 'Latency (hours:min:sec)' : 'Latency (min:sec)';
        return getStatusFeedItem({
            label,
            value: latency,
            formattedValue,
            isValidFunc: isValidLatency,
            description,
        });
    }

    function getCoverageItem({ coverageValue, unmatchedTrips }: RtCoverage, sectionName: string, description?: string) {
        const formattedValue = coverageValue !== null ? `${coverageValue} %` : '-';
        const csvData = unmatchedTrips.map(t => toReportRow(UnmatchedTripsReportDef, t));
        return getStatusFeedItem({
            label: 'Coverage',
            value: coverageValue,
            formattedValue,
            isValidFunc: isValidCoverage,
            csvData,
            sectionName,
            description,
        });
    }

    function getStatusFeedItem({ value, formattedValue, label, isValidFunc, description, downloadFileFunc, fileName, csvData, sectionName }: StatusFeedItemParameter) {
        return <>
            <Statistic>
                <Statistic.Value>
                    {isValidFunc(value)
                        ? <Icon name="check circle" color="green" />
                        : <Icon name="exclamation circle" color="red" />
                    }
                    {formattedValue ?? (value == null ? '-' : value)}
                </Statistic.Value>
                <Statistic.Label>
                    {label}
                    {downloadFileFunc &&
                        <Popup content="Download Feed" trigger={
                            <Label as="a" onClick={() => { downloadFeed(fileName ?? 'feedData', downloadFileFunc); }} basic pointing="left" color="grey">
                                <Icon name="download" />
                            </Label>}
                        />}
                    {csvData &&
                        <Popup content="Download Missing Trips" trigger={
                            <CSVLink
                                headers={UnmatchedTripsReportHeader}
                                data={csvData}
                                filename={`${sectionName ?? ''}_MissingTrips_${moment(new Date()).format('YYYY_MM_DD_h_mm_A')}.csv`}
                                target="_blank"
                                className="ui grey left pointing basic label"
                            >
                                <Icon name="download" />
                            </CSVLink>
                        }
                        />}
                    {description && <div style={styles.statusFeedItemLabel}>{description}</div>}
                </Statistic.Label>
            </Statistic>
        </>;
    }

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Service Status
                </Header>
                {dataState &&
                    <>
                        <Form.Group className="rowGroup">
                            <Message info>
                                <Message.Header>Service Information (local time: {moment.parseZone(dataState.serviceStatusLocalTime).format('YYYY-MM-DD h:mm A')})</Message.Header>
                                {dataState.responseMessages.map((message, index) => (
                                    <p key={index}>{message}</p>
                                ))}
                            </Message>
                        </Form.Group>
                        <Form.Group>
                            <Step.Group widths={3}>
                                <Step completed={isVehiclesStepCompleted(dataState.agencyData)}>
                                    {getStepNotificationIcon()}
                                    <Step.Content>
                                        <Step.Title>Trip Assignment Feed</Step.Title>
                                        {dataState.agencyData.lastRecordLocalTime &&
                                            <Step.Description>Last record time: {moment.parseZone(dataState.agencyData.lastRecordLocalTime).format('YYYY-MM-DD h:mm A')}</Step.Description>
                                        }
                                    </Step.Content>
                                </Step>
                                <Step completed={isVehiclesStepCompleted(dataState.redisData)}>
                                    {getStepNotificationIcon()}
                                    <Step.Content>
                                        <Step.Title>Redis Cache</Step.Title>
                                    </Step.Content>
                                </Step>
                                <Step completed={isStepCompleted(dataState.transitIqData)}>
                                    {getStepNotificationIcon()}
                                    <Step.Content>
                                        <Step.Title>Transit IQ</Step.Title>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getCoverageItem(dataState.agencyData.vehiclesCoverage, 'TripAssignmentFeed')}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getCoverageItem(dataState.redisData.vehiclesCoverage, 'Redis')}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getCoverageItem(dataState.transitIqData.vehiclesCoverage, 'TransitIQ')}
                                </Statistic.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <Message attached="top" info>
                                    <p>"Coverage" is a portion of active trips according to the schedule which have related data in GTFS-RT feed.</p>
                                </Message>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getLatencyItem(dataState.agencyData.vehiclesLatency)}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getLatencyItem(dataState.redisData.vehiclesLatency)}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getLatencyItem(dataState.transitIqData.vehiclesLatency)}
                                </Statistic.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <Message attached="top" info>
                                    <p>"Latency" shows the average delay between the vehicle position time and the current time.</p>
                                </Message>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Vehicle Positions',
                                        value: dataState.agencyData.vehiclesCount,
                                        isValidFunc: isValidNumber,
                                        fileName: 'vehicles',
                                        downloadFileFunc: getAgencyVehiclesFeed,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Vehicle Positions',
                                        value: dataState.redisData.vehiclesCount,
                                        isValidFunc: isValidNumber,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Vehicle Positions',
                                        value: dataState.transitIqData.vehiclesCount,
                                        isValidFunc: isValidNumber,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <Message attached="top" info>
                                    <p>Coverage, Latency and Vehicle Positions are only for those records which are not later than {dataState.maxTimeDiffMin} minutes.</p>
                                </Message>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Service Alerts',
                                        value: dataState.agencyData.alertsCount,
                                        isValidFunc: isValidNumber,
                                        fileName: 'service-alerts',
                                        downloadFileFunc: getAgencyAlertsFeed,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Service Alerts',
                                        value: dataState.redisData.alertsCount,
                                        isValidFunc: isValidNumber,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                            <Form.Field width={6}>
                                <Statistic.Group horizontal size="small">
                                    {getStatusFeedItem({
                                        label: 'Service Alerts',
                                        value: dataState.transitIqData.alertsCount,
                                        isValidFunc: isValidNumber,
                                    })}
                                    {getStatusFeedItem({
                                        label: 'Trip Updates',
                                        value: dataState.transitIqData.tripsCount,
                                        isValidFunc: isValidNumber,
                                    })}
                                </Statistic.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group className="bottomGroup">
                            <Form.Field width={16}>
                                <Message attached="top" info>
                                    <p>Trip Updates also contain arrivals predictions for upcoming trips.</p>
                                </Message>
                            </Form.Field>
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(ServiceStatusForm);
