import { OperationResult } from '../types/apiTypes';
import { BusHistoryArrival, BusPosition, BusPositionByBusNumber, BusPositionTrip, PredictionOnStop, RtVehiclePosition, StopPredictionDto, TripStopData } from '../types/busHistoryTypes';
import { GtfsStop2 } from '../types/gtfsTypes';
import { KeyValuePair } from '../types/types';
import { VehicleStopArrival } from '../types/vehicleArrivalsTypes';
import { VehicleStatisticsData } from '../types/vehicleStatisticsTypes';
import api from './api';
import { buildUrlString } from './helpers';

class VehiclesHisotryApi {
    static vehiclesHistoryRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/VehiclesHistory/${url}`);
    static onTimePerformanceRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/OnTimePerformance//${url}`);
    static notificationsApiRequests = async (url: string) => await api.doAuthNotificationApiGetRequestAsync(`/gtfshistorical/${url}`);

    static async getVehiclesStatistics(agencyId: string, fromDate: string, toDate: string) {
        const url = buildUrlString(null, 'VehiclesStatistics', agencyId, fromDate, toDate);
        return await this.vehiclesHistoryRequest<OperationResult<VehicleStatisticsData[]>>(url);
    }
    static async getVehicleStopArrivals(agencyId: string, vehicleId: string, serviceDate: string) {
        const url = buildUrlString(null, 'VehicleStopArrivals', agencyId, vehicleId, serviceDate);
        return await this.vehiclesHistoryRequest<OperationResult<VehicleStopArrival[]>>(url);
    }
    static async getVehicles(agencyId: string, date: string) {
        const url = buildUrlString(null, 'vehicles', agencyId, date);
        return await this.vehiclesHistoryRequest<OperationResult<KeyValuePair<string, string>[]>>(url);
    }
    static async getTripsData(agencyId: string, date: string, vehicleId: string) {
        const queryParams = {
            vehicleId,
        };
        const url = buildUrlString(queryParams, 'TripsData', agencyId, date);
        return await this.vehiclesHistoryRequest<OperationResult<BusPositionTrip[]>>(url);
    }
    static async getVehiclePositions(agencyId: string, date: string, tripId: string, vehicleId: string) {
        const queryParams = {
            tripId,
            vehicleId,
        };
        const url = buildUrlString(queryParams, 'BusPositionsByBusNumber', agencyId, date);
        return await this.vehiclesHistoryRequest<OperationResult<BusPositionByBusNumber[]>>(url);
    }

    static async getBusHistoryArrivals(agencyId: string, date: string, tripId: string, vehicleId: string) {
        const queryParams = {
            tripId,
            vehicleId,
        };
        const url = buildUrlString(queryParams, 'BusHistoryArrivals', agencyId, date);
        return await this.vehiclesHistoryRequest<OperationResult<BusHistoryArrival[]>>(url);
    }

    static async getRtVehiclePositionData(agencyId: string, tripId: string, vehicleId: string) {
        const url = buildUrlString(null, 'OnTimePerformance', 'RtVehiclePositionData', agencyId, vehicleId, tripId);
        return await api.doAuthGetRequestAsync<OperationResult<RtVehiclePosition[]>>(url);
    }

    static async getTripStopsData(agencyId: string, tripId: string, reportDate: Nullable<string> = null) {
        const queryParams = {
            reportDate,
        };
        const url = buildUrlString(queryParams, 'OnTimePerformance', 'TripStopsData', agencyId, tripId);
        return await api.doAuthGetRequestAsync<OperationResult<TripStopData[]>>(url);
    }

    static async getStops(agencyId: string, filter: string, directionVariantId: string | undefined, routeId: string | undefined) {
        // const queryParams = filter ? { filter } : null;
        const queryParams = filter
? {
            filter,
            directionVariantId,
            routeId,
        }
: null;
        const url = buildUrlString(queryParams, 'stops', agencyId);
        return await this.vehiclesHistoryRequest<OperationResult<GtfsStop2[]>>(url);
    }

    static async getSchedule(serviceDate: string, stopId: string, directionVariantId: string) {
        const queryParams = {
            serviceDate,
            stopId,
            directionVariantId,
        };
        const url = buildUrlString(queryParams, 'schedule');
        return await this.vehiclesHistoryRequest<OperationResult<Date[]>>(url);
    }

    static async getPositions(agencyId: string, directionVariantId: string, serviceDateTime: string): Promise<OperationResult<BusPosition[]>> {
        const queryParams = {
            directionVariantId,
            serviceDateTime,
        };
        const url = buildUrlString(queryParams, 'Positions', agencyId);
        return await this.vehiclesHistoryRequest<OperationResult<BusPosition[]>>(url);
    }

    static async getStopPredictions(agencyId: string, stopId: string, directionVariantId: string, serviceDateTime: string): Promise<OperationResult<StopPredictionDto[]>> {
        const queryParams = {
            stopId,
            directionVariantId,
            serviceDateTime,
        };
        const url = buildUrlString(queryParams, 'StopPredictions', agencyId);
        return await this.vehiclesHistoryRequest<OperationResult<StopPredictionDto[]>>(url);
    }

    static async getPredictionsByPosition(agencyId: string, serviceDate: string, tripId: string, vehicleTime: string, lat: Latitude, lon: Longitude): Promise<PredictionOnStop[]> {
        const queryParams = {
            serviceDate,
            tripId,
            vehicleTime,
            lat,
            lon,
        };
        const url = buildUrlString(queryParams, agencyId, 'tripPredictionsOnStops');
        return await this.notificationsApiRequests(url);
    }
}

export default VehiclesHisotryApi;